import { Unit } from '~/types/Unit';
import { Gateway } from './gateway';
import { companyIdAttachingNetworkManager } from './network/companyIdAttachingNetworkManager';
import { MagicRequest, RequestMethod } from './network/magicRequest';

const COMPANY_UNITS_LISTING_URL = '/api/unit-listings';

const UNITS_LISTING_URL = '/api/units';

class UnitGateway extends Gateway {
  public async getUnitsListing(): Promise<Unit[]> {
    const request: MagicRequest = {
      url: UNITS_LISTING_URL,
      method: RequestMethod.GET,
      headers: this.createCommonHeaders(),
    };
    const response: Response = await this.sendRequest(request);
    const json = await response.json();
    return this.createUnitListingFormJson(json);
  }

  private createUnitListingFormJson = (json: any): Unit[] => {
    return json.map((unit: any): Unit => {
      return {
        id: unit.id,
        name: unit.name,
        beds: unit.beds,
        baths: unit.baths,
        unitSizeSqft: unit.unitSizeSqft,
        address: unit.property.address,
        isAvailable: unit.available,
        isActive: unit.active,
        images: unit.images,
        propertyId: unit.propertyId,
        portfolioId: unit.portfolioId,
      };
    });
  };
}

export const unitGateway = new UnitGateway(companyIdAttachingNetworkManager);
